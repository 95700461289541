import React from "react"
import { Images } from "~data"
import { Col, Container, Row } from "react-bootstrap"
import Card from "./Components/Card"
import CardStyle from "./style"

export default function ComoIntegrarteSection({ ...rest }) {
  return (
    <CardStyle {...rest} backgroundColor="#f3f4f6" id="como-integrarte-section">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xxl-6 col-md-8 col-xs-10">
            <CardStyle.Box className="text-center">
              <CardStyle.Title as="h2">
                Como integrarte a Enefevo
              </CardStyle.Title>
              <CardStyle.Subtitle as="h3">
                Un sencillo y amigable proceso personalizado de 3 pasos asistido
                por expertos
              </CardStyle.Subtitle>
            </CardStyle.Box>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row className="justify-content-center d-flex align-items-stretch mt-5">
          <Col className="col-xxl-3 col-md-4 col-12">
            <Card
              title="1. Regístrate"
              text="Registrate en nuestro sitio Enefevo y descarga la API"
              icon={Images.Integragion.RegisterIcon}
            />
          </Col>
          <Col className="col-xxl-3 col-md-4 col-12">
            <Card
              title="2. Certifícate"
              text="Envía tus documentos fiscales, firma el contrato y realiza la certificación con nuestro equipo"
              icon={Images.Integragion.CertIcon}
            />
          </Col>
          <Col className="col-xxl-3 col-md-4 col-xs-12">
            <Card
              title="3. Actívate"
              text="Recibe tus credenciales productivas y comienza a recibir pagos por enefevo"
              icon={Images.Integragion.ReceiveIcon}
            />
          </Col>
        </Row>
      </Container>
    </CardStyle>
  )
}
