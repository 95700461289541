import React from "react"
import ProcessCard from "./Components/Card"
import { Container, Row, Col } from "react-bootstrap"
import Process from "./style"
import { Images } from "~data"

const Data = [
  {
    id: "1",
    image: Images.Experience.CodeIcon,
    text: "Cliente recibe su referencia de pago enefevo",
  },
  {
    id: "2",
    image: Images.Experience.StoreIcon,
    text: "Acude a una tienda de la red Enefevo con su referencia",
  },
  {
    id: "3",
    image: Images.Experience.ScannerIcon,
    text: "Cajero escanea el código QR o captura la referencia",
  },
  {
    id: "4",
    image: Images.Experience.POSIcon,
    text: "El POS regresa el monto y vencimiento",
  },
  {
    id: "5",
    image: Images.Experience.PayIcon,
    text: "Se realiza el cobro del servicio",
  },
  {
    id: "6",
    image: Images.Experience.TicketIcon,
    text: "Se entrega el ticket como comprobante del pago en línea",
  },
]

export default function ExperienciaUsuarioSection({ ...rest }) {
  return (
    <Process id="experiencia-usuario-section">
      <Container>
        <Process.Box pb="30p" pbXL="50px">
          <Row>
            <Col xs="12" className="text-center">
              {/* <Process.Subtitle as="h5">Our Process</Process.Subtitle> */}
              <Process.Title as="h2">Experiencia de Usuario</Process.Title>
            </Col>
          </Row>
        </Process.Box>
        <Process.CardContainer>
          {Data.map(({ id, image, text }) => (
            <ProcessCard image={image} text={text} key={id} />
          ))}
        </Process.CardContainer>
        <Process.Box>
          <Process.Subtitle as="h5">El ticket registra:</Process.Subtitle>
          <Row className="justify-content-center">
            <Col className="col-lg-4 col-md-4 col-xs-12">
              <ul>
                <Process.Item>Nombre del servicio pagado</Process.Item>
                <Process.Item>Autorización</Process.Item>
                <Process.Item>Número de referencia</Process.Item>
              </ul>
            </Col>
            <Col className="col-lg-4 col-md-4 col-xs-12">
              <ul>
                <Process.Item>Monto de la transacción</Process.Item>
                <Process.Item>
                  Leyenda del facturador correspondiente
                </Process.Item>
              </ul>
            </Col>
          </Row>
        </Process.Box>
      </Container>
    </Process>
  )
}
