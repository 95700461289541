import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import SectionTitle from "./components/SectionTitle"
import Widget from "./components/widget"
import { StaticImage as Img } from "gatsby-plugin-image"
import Beneficios from "./style"

export default function BeneficiosSection() {
  return (
    <Beneficios backgroundColor="#fff" id="beneficios-section">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-6 col-lg-8 col-md-9 col-xs-10 text-center">
            <SectionTitle
              title="Beneficios"
              titleProps={{ mb: "50px" }}
              subTitleProps={{ mb: "15px" }}
            />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-2 order-lg-1">
            <Widget
              directionXS="row-reverse"
              title="Notificaciones en tiempo real"
              icon="fa fa-bell"
              text="Notificaciones en tiempo real."
            />
            <Widget
              directionXS="row-reverse"
              title="Excelente experiencia"
              icon="fas fa-medal"
              text="Ofrece a tus clientes una alternativa más para pagar en efectivo."
            />
            <Widget
              directionXS="row-reverse"
              title="Cero contracargos"
              icon="fab fa-creative-commons-nc"
              text="Con enefevo eliminas los problemas de Contracargos."
            />
          </Col>
          <Col
            xs="12"
            className="col-xxl-6 col-lg-4 col-md-8 col-xs-9 order-1 order-lg-2 text-center"
          >
            <Beneficios.ImageContent>
              <Img
                src="../../assets/image/sections/dashboard.png"
                alt="content"
                layout="fullWidth"
                placeholder="blurred"
              />
            </Beneficios.ImageContent>
          </Col>
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-3">
            <Widget
              directionXS="row"
              title="Dashboard"
              icon="fa fa-chart-pie"
              text="Contar con un Tablero de control amigable y personalizado."
            />
            <Widget
              directionXS="row"
              title="Más opciones"
              icon="fas fa-filter"
              text="Incrementar las opciones para recibir tus pagos."
            />
            <Widget
              directionXS="row"
              title="24/7"
              icon="fa fa-cog"
              text="Atención experta los 365 días del año."
            />
          </Col>
        </Row>
      </Container>
    </Beneficios>
  )
}
