import React from "react"
import { PageWrapper } from "~components"
import {
  HomeSection,
  SabiasQueSection,
  QueEsSection,
  ExperienciaUsuarioSection,
  ComoIntegrarteSection,
  BeneficiosSection,
  ProcesoSection,
  ContactoSection,
  FooterSection,
} from "~sections"
const header = {
  headerClasses:
    "site-header site-header--menu-end light-header site-header--sticky logo",
  containerFluid: false,
}

export default function index() {
  return (
    <PageWrapper headerConfig={header}>
      <HomeSection />
      <SabiasQueSection />
      <QueEsSection />
      <ProcesoSection />
      <ExperienciaUsuarioSection />
      <ComoIntegrarteSection />
      <BeneficiosSection />

      <ContactoSection />
      <FooterSection />
    </PageWrapper>
  )
}
