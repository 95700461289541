import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Hero from "./style"
import { Images } from "~data"

export default function HomeSection() {
  return (
    <Hero
      id="home-section"
      style={{ backgroundImage: `url(${Images.Sections.HomeImage})` }}
    >
      <Container>
        <Row className="align-items-center justify-content-end justify-content-md-end">
          <Col className="col-xxl-5 col-xl-5 col-lg-9 col-md-10 col-10">
            <Hero.Content>
              <Hero.Title as="h2" fontColor="#014D67">
                La mejor <br className="d-none d-sm-block" />
                experiencia de <br className="d-none d-sm-block" />
                pago en efectivo
              </Hero.Title>                         
            </Hero.Content>
          </Col>
        </Row>
      </Container>
    </Hero>
  )
}
