import React, { useEffect, useState } from "react"
import { Link } from "~components"
import { Container, Row, Col } from "react-bootstrap"
import { Images } from "~data"
import Footer from "./style"

export default function FooterSection() {
  const [currentYear, setCurrentYear] = useState(2021)
  useEffect(() => {
    const year = new Date().getFullYear()
    setCurrentYear(year)
  }, [])

  return (
    <Footer>
      <Container>
        <Footer.Box pbXL="95px">
          <Row className="justify-content-center">
            <Col xs="12" className="col-lg-4 col-md-12">
              <Footer.Widgets className="footer-widgets footer-widgets--l7">
                <Footer.Box mb="30px">
                  <Link to="#">
                    <img
                      style={{ width: 200, heigth: "auto" }}
                      src={Images.Logo}
                      alt="logo"
                    />
                  </Link>
                </Footer.Box>
                <Footer.Text mb="36px">
                  La Mejor experiencia de pago en efectivo, con una amplia
                  cobertura de ventanillas de pago en importantes cadenas
                  comerciales, a la disposición de tu negocio y para la
                  comodidad de tus clientes.
                </Footer.Text>
                <Footer.SocialShare>
                  <Footer.SocialShareItem>
                    <a href="https://www.facebook.com/Enefevo">
                      <i className="fab fa-facebook-square" />
                    </a>
                  </Footer.SocialShareItem>
                  <Footer.SocialShareItem>
                    <a href="https://www.instagram.com/eenefevo/">
                      <i className="fab fa-instagram" />
                    </a>
                  </Footer.SocialShareItem>
                </Footer.SocialShare>
              </Footer.Widgets>
            </Col>

            {/* <Col xs="12" className="col-lg-3 col-md-12">
              <Footer.Widgets>
                <Footer.Title>Detalles de contacto</Footer.Title>
                <Footer.Address className="widgets-address">
                  <Footer.AddressItem style={{ flexDirection: "column" }}>
                    <div>
                      <i className="fa fa-map-marker-alt" /> Dirección:
                    </div>
                    <div style={{ marginLeft: 44 }}>
                      Albino Espinosa 1139 Pte,
                      <br className="d-block" />
                      Col. Centro, Monterrey, NL 64000
                    </div>
                  </Footer.AddressItem>
                  <Footer.AddressItem>
                    <i className="fa fa-phone-alt" />

                    <a href="#">
                      <span> Teléfono: </span>
                      <br className="d-block" /> +52 (81) 8130 8700
                    </a>
                  </Footer.AddressItem>
                  <Footer.AddressItem>
                    <i className="fa fa-envelope" />
                    <a href="mailto:info@medcartel.com">
                      <span className="d-block"> Email:</span>
                      contacto@enefevo.mx
                    </a>
                  </Footer.AddressItem>
                </Footer.Address>
              </Footer.Widgets>
            </Col> */}

            <Col xs="12" className="col-lg-3 col-md-12">
              <Footer.Widgets>
                <Footer.Title style={{ textAlign: "center" }}>
                  Más Links
                </Footer.Title>
                <Footer.List>
                  <Footer.ListItems>
                    <a href="https://www.datalogic.com.mx/">Datalogic</a>
                  </Footer.ListItems>
                  <Footer.ListItems>
                    <a href="#">Términos y condiciones</a>
                  </Footer.ListItems>
                  <Footer.ListItems>
                    <a href="#">Aviso de privacidad</a>
                  </Footer.ListItems>
                </Footer.List>
              </Footer.Widgets>
            </Col>

            {/* <Col xs="12" className="col-lg-6">
              <Row>
                <Col xs="12" className="col-md-4 col-xs-6">
                  <Footer.Widgets>
                    <Footer.Title>Detalles de contacto</Footer.Title>
                    <Footer.Address className="widgets-address">
                      <Footer.AddressItem>
                        <i className="fa fa-map-marker-alt" />
                        <span>
                          Dirección: <br className="d-block" />
                          Albino Espinosa 1139 pte,
                          <br className="d-block" />
                          Col. Centro
                          <br className="d-block" />
                          Monterrey, NL 64000
                        </span>
                      </Footer.AddressItem>
                      <Footer.AddressItem>
                        <i className="fa fa-phone-alt" />

                        <a href="#">
                          <span> Teléfono: </span>
                          <br className="d-block" /> +52 (81) 8130 8700
                        </a>
                      </Footer.AddressItem>
                      <Footer.AddressItem>
                        <i className="fa fa-envelope" />
                        <a href="mailto:info@medcartel.com">
                          <span className="d-block"> Email:</span>
                          contacto@enefevo.mx
                        </a>
                      </Footer.AddressItem>
                    </Footer.Address>
                  </Footer.Widgets>
                </Col>
                <Col xs="12" className="col-md-4 col-xs-6">
                  <Footer.Widgets>
                    <Footer.Title>Más Links</Footer.Title>
                    <Footer.List>
                      <Footer.ListItems>
                        <a href="#">Datalogic</a>
                      </Footer.ListItems>
                      <Footer.ListItems>
                        <a href="#">Términos y condiciones</a>
                      </Footer.ListItems>
                      <Footer.ListItems>
                        <a href="#">Aviso de privacidad</a>
                      </Footer.ListItems>
                    </Footer.List>
                  </Footer.Widgets>
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Footer.Box>
        <Footer.Copyright>
          <Footer.CopyrightText>
            © {currentYear} Enefevo. All Rights Reserved
          </Footer.CopyrightText>
        </Footer.Copyright>
      </Container>
    </Footer>
  )
}
