import React from "react"
import Card from "./style"

export default function TeamCard({ icon, title, text, ...rest }) {
  return (
    <Card itemCenter={true} backgroundColor="#fff" {...rest}>
      <Card.Icon as="div">
        <img
          className="w-100"
          src={icon}
          alt="certificate"
          layout="fullWidth"
          placeholder="blurred"
        />
      </Card.Icon>
      <Card.Title as="h4">{title}</Card.Title>
      <Card.Text>{text}</Card.Text>
    </Card>
  )
}
