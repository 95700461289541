import styled from "styled-components/macro"
import { Box, Heading, Paragraph } from "~styled"

const Style = styled(Box)`
  position: relative;
  overflow: hidden;
  padding-top: 55px;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  @media (min-width: 992px) {
    padding-top: 125px;
    padding-bottom: 105px;
  }
  /* .newsletter-form {
        max-width: 521px;
    } */
`
Style.Shapes = styled(Box)`
  position: absolute;
  right: 0;
  top: -7%;
  width: 30%;
  z-index: 1;
`
Style.ShapeTwo = styled(Box)`
  width: 50%;
  position: absolute;
  top: 30%;
  right: -30%;
  z-index: -1;
`
Style.Title = styled(Heading)`
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.1;
  margin-bottom: 35px;

  @media (min-width: 480px) {
    font-size: 28px;
  }

  @media (min-width: 768px) {
    letter-spacing: -2px;
    font-size: 40px;
  }

  @media (min-width: 992px) {
    font-size: 46px;
  }
`
Style.Text = styled(Paragraph)`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.62;
  opacity: 0.7;
  @media (min-width: 992px) {
    font-size: 21px;
  }
`

Style.TabNavWrapper = styled(Box)`
  @media (min-width: 1200px) {
    padding-right: 65px;
  }
  @media (min-width: 1400px) {
    padding-right: 110px;
  }
`

Style.List = styled.ol`
  margin: 40px 0 20px;
`

Style.ListItem = styled.li`
  color: white;
  opacity: 0.8;
  font-size: 14px;
  font-weight: 500;
`

Style.Box = styled(Box)``
export default Style
