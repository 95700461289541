import styled from "styled-components/macro"
import { Box, Heading, Button, Paragraph } from "~styled"

const Beneficios = styled(Box)`
  padding-top: 35px;
  padding-bottom: 40px !important;

  @media (min-width: 768px) {
    padding-top: 75px;
    padding-bottom: 25px;
  }

  @media (min-width: 992px) {
    padding-top: 120px;
    padding-bottom: 0px;
  }
`

Beneficios.ImageContent = styled(Box)`
  position: relative;
  z-index: 1;
  width: 80%;
  margin: 0 auto 40px auto;
`
Beneficios.Shape = styled(Box)`
  position: absolute;
  top: 50%;
  left: 56%;
  z-index: -1;
  transform: translate(-50%, -50%);
  width: 90%;
`
Beneficios.Box = styled(Box)``

export default Beneficios
