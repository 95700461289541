import styled from "styled-components/macro"
import { Box, Heading, Paragraph } from "~styled"

const Process = styled(Box)`
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 36px;

  @media (min-width: 768px) {
    padding-top: 70px;
    padding-bottom: 55px;
  }

  @media (min-width: 992px) {
    padding-top: 110px;
    padding-bottom: 100px;
  }
`
Process.Subtitle = styled(Heading)`
  color: #01425e;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.75;
  text-align: center;
  margin-bottom: 20px;
`
Process.Title = styled(Heading)`
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
  font-size: 30px;
  color: #262729;
  margin-bottom: 45px;

  @media (min-width: 768px) {
    font-size: 38px;
  }

  @media (min-width: 992px) {
    font-size: 45px;
    margin-bottom: 0;
  }
`
Process.Box = styled(Box)`
  margin: 30px 0;
`

Process.Item = styled.li`
  color: #014d67;
`

Process.CardContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`

export default Process
