import React from "react"

import Card from "./style"
export default function ProcessCard({ text, image, ...rest }) {
  return (
    <Card {...rest}>
      <Card.Icon as="div">
        <img src={image} alt="experience" />
      </Card.Icon>
      <Card.Text as="p">{text}</Card.Text>
    </Card>
  )
}
