import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Images } from "~data"
import Style from "./style"

const ProcesoSection = ({ ...rest }) => {
  return (
    <Style
      className=""
      style={{ backgroundColor: "#014D67" }}
      id="proceso-section"
    >
      <Container>
        <Row>
          <Col xs="auto" className="col-xl-8 col-lg-10">
            <Style.Box mb="35px" mbMD="80px" mbLG="148px">
              <Style.Title className="mb-5" as="h2" fontColor="#FFF">
                Proceso de plataforma enefevo
              </Style.Title>
            </Style.Box>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center">
          <Col className="col-md-8" xs="12">
            <img
              src={Images.Process.DiagramPngIcon}
              className="w-100"
              alt="shape1"
            />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col className="col-md-8" xs="12">
            <Style.List>
              <Style.ListItem>
                Cliente realiza compra en tu sitio web
              </Style.ListItem>
              <Style.ListItem>
                Tu servidor crea un cargo y genera un recibo de pago
                personalizado
              </Style.ListItem>
              <Style.ListItem>
                Cliente Selecciona Enefevo como medio de pago, confirma su
                compra y acude a sucursal afiliada a realizar el pago
              </Style.ListItem>
              <Style.ListItem>
                Datalogic valida, recibe el pago y notifica la recepción a tu
                servidor
              </Style.ListItem>
            </Style.List>
          </Col>
        </Row>
      </Container>
    </Style>
  )
}

export default ProcesoSection
