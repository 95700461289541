import React from "react"
import SectionTitle from "./components/SectionTitle"
import { StaticImage as Img } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import Widget from "./components/Widget"
import Content from "./style"

export default function QueEsSection() {
  return (
    <Content backgroundColor="#ededed" id="que-es-section">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col className="col-xxl-6 col-lg-7 col-md-9 col-xs-10 order-2 orde-lg-1">
            <Content.Box mbLG="60px" mb="30px">
              <SectionTitle
                title="Qué es enefevo"
                text="Enefevo es la  Plataforma de pagos en efectivo  con la Red más grande de México, 
                que puede acercar tu negocio a más personas, permitiendo pagos en efectivo en su tienda 
                más cercana. Al operar con enefevo permites a tus Clientes comprar en línea y pagar en 
                efectivo  en Redes comerciales afiliadas a DATALOGIC, incorporando más de 100,000  
                puntos de contacto a nivel nacional."
                subTitleProps={{ mb: "20px" }}
                titleProps={{ mb: "10px" }}
              />
            </Content.Box>
            <Content.Box>
              <Row className=" justify-content-center">
                <Col className="col-lg-6 col-xs-6">
                  <Widget
                    icon="fas fa-laptop-code"
                    title="Sencilla integración"
                    text="Integración fácil, segura y económica"
                  />
                </Col>
                <Col className="col-lg-6 col-xs-6">
                  <Widget
                    icon="fa fa-layer-group"
                    title="Gran cobertura"
                    text="Plataforma universal, amigable y segura"
                  />
                </Col>
              </Row>
            </Content.Box>
          </Col>

          <Col
            xs="12"
            className="col-xxl-6 col-lg-5 col-md-8 col-xs-10 order-1 order-lg-2"
          >
            <Img
              src="../../assets/image/sections/celular-enefevo.png"
              alt="content"
              layout="fullWidth"
              placeholder="blurred"
            />
          </Col>
        </Row>
      </Container>
    </Content>
  )
}
