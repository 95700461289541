import React from "react"
import SabiasQue from "./style"
import { Col, Row, Container } from "react-bootstrap"
import { SuperTag } from "~components"

export default function SabiasQueSection({ colClasses, title, text, ...rest }) {
  return (
    <SabiasQue backgroundColor="#fff" {...rest} id="sabias-que-section">
      <Container>
        <Row className="row justify-content-center text-center">
          <Col className={colClasses ? colClasses : "col-xl-7"}>
            <SabiasQue.Title as="h2">Sabías que...</SabiasQue.Title>
            <SabiasQue.Text>
              <SuperTag
                value="Más del 70% de la población en México no está bancarizada<br/>
        El efectivo sigue dominando las formas de pago en México y Latinoamérica.<br/>
        Más del 80% de los pagos en el país se realizan en efectivo.<br/>
        Comprar en línea y pagar en efectivo es una tendencia que está creciendo en América Latina."
              />
            </SabiasQue.Text>
          </Col>
        </Row>
      </Container>
    </SabiasQue>
  )
}
