import styled from "styled-components/macro"

import { Box, Heading, Paragraph } from "~styled"

const Card = styled(Box).attrs({ className: "process-card" })`
  position: relative;
  flex: 1 1 0;
  flex-direction: column;
  text-align: center;
  padding: 8px;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;

    &::before {
        display: none;
    }
  }

  &::before {
    position: absolute;
    top: 44px;
    right: 0;
    opacity: 0.5;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f061";
    font-size: 36px;
    transform: translateX(50%);
  }

  @media (max-width: 992px) {
    margin-bottom: 46px;
    &::before {
      top: unset;
      right: unset;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
    }
  }
`
Card.Icon = styled(Paragraph)`
  width: 120px;
  height: 120px;
  margin: 0 auto 40px;

  & > img {
    width: auto;
    height: 100%;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    width: 70px;
    height: 70px;
  }
`
Card.Title = styled(Heading)`
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.42857;
  margin-bottom: 25px;
`
Card.Text = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
`

export default Card
