import styled from "styled-components/macro"
import { Box, Heading, Paragraph } from "~styled"

const Card = styled(Box).attrs({ className: "service-card" })`
  border: none;
  height: 100%;
  padding-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 6px;
  transition: 0.4s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #25373f;
  @media (min-width: 575px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (min-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (min-width: 992px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  &:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  }
`
Card.Icon = styled(Box)`
  min-width: 120px;
  max-width: 120px;
  min-height: 120px;
  max-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  background: ${props => props.background};
`
Card.Title = styled(Heading)`
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: #ce6021;
`
Card.Text = styled(Paragraph)`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.86666;
  margin-bottom: 26px;
  opacity: 0.7;
  color: inherit;
`
Card.Button = styled(Box)`
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: inherit;
  transition: 0.4s;

  i {
    margin-left: 10px;
  }
`

export default Card
